// <!-- API -->
import { ref, computed } from 'vue';

// <!-- UTILITIES -->
import { getFileType } from '@/utils/file';

// <!-- COMPOSABLES -->
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- COMPONENTS -->
import SelectProfileCellRenderer from '~CSVUploader/components/cells/SelectProfileCellRenderer.vue';

// <!-- TYPES -->
import { Store } from 'vuex';
import { UploadRecord } from '@/store/types/uploader/state';
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

/**
 * Get the profile selection grid.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Store} store
 * @param {Object} payload
 * @param {V.Ref<Map<String, UploadRecord>>} payload.records
 * @param {V.Ref<Map<Number, import('@/models/v1/mappings/MappingProfile').MappingProfileResource>>} payload.profileIndex
 * @param {V.Ref<Number>} payload.mountKey
 * @param {Object} api
 * @param {(event: { filename: String }) => void} api.onViewRecordMappingProfile
 * @param {(event: { filename: String }) => void} api.onEditRecordMappingProfile
 * <!--  param {(event: { filename: String, definition: Omit<MappingProfileResource, 'id'|'accountId'|'dateCreated'|'createdBy'> & Partial<MappingProfileResource> }) => Promise<Awaited<UploadRecord>>} api.onProfileCreated -->
 * <!--  param {(event: { filename: String, profile: Pick<MappingProfileResource, 'id'> }) => Promise<Awaited<UploadRecord>>} api.onProfileSelected -->
 * <!-- param {(event: { filename: String }) => Promise<Awaited<UploadRecord>>} api.onProfileDeselected -->
 */
export function useProfileSelectGrid(
    { emit },
    // ts-ignore
    { commit },
    { records, profileIndex, mountKey },
    { onViewRecordMappingProfile, onEditRecordMappingProfile }
) {
    /** Get grid API. */
    // ts-ignore
    const { ...grid } = useAgGridVue();

    const defaultColDef = Object.freeze({
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressMovable: true,
        suppressMenu: true,
        lockPosition: true,
        minWidth: 150,
        flex: 1,
        cellClass: 'flex justify-center items-center',
    });

    /**
     * Selected profile row data.
     * @type {V.ComputedRef<({ name: String, type: String, size: Number,
     *                       location: LocationResource,
     *                       profile: import('@/models/v1/mappings/MappingProfile').MappingProfileResource,
     *                       suggestion?: import('@/models/v1/mappings/MappingProfile').MappingProfileResource,
     *                       viewing?: Boolean, editing?: Boolean,
     *                       creating?: Boolean, created?: Boolean,
     *                       applying?: Boolean, applied?: Boolean,
     *                       ingesting?: Boolean, ingested?: Boolean,
     *                       isPEMFile?: Boolean })[]>}
     */
    const selectedRecords = computed(() => {
        // ts-ignore
        const _ = mountKey.value;
        return [...records.value.values()].map((r) => {
            const {
                filename,
                file,
                location,
                mappingProfile,
                suggestedMappingProfile,
            } = r;
            const selected = r.isMappingProfileSelected
                ? mappingProfile.value
                : null;
            return {
                name: filename,
                type: file.exists ? file.value.type : '',
                size: file.exists ? file.value.size : 0,
                location: location.exists ? location.value : null,
                profile: selected,
                suggestion: r.isMappingProfileSuggested
                    ? suggestedMappingProfile
                    : null,
                viewing: r.isViewingMappingProfile,
                editing: r.isEditingMappingProfile,
                creating: r.isCreatingMappingProfile,
                created: r.isMappingProfileCreated,
                applying: r.isApplyingMappingProfile,
                applied: r.isMappingProfileApplied,
                ingesting: r.isDatasetBatchIngesting,
                ingested: r.isDatasetBatchIngested,
                isPEMFile: r.isMarkedAsPEM,
            };
        });
    });

    /** Updating flag. @type {Set<'uploading'>} */
    const status = ref(new Set());

    /** Computed property. */
    const isUpdatingSelection = computed(() => {
        const _ = status.value;
        return _.has('updating');
    });

    /** References. */
    const state = {
        defaultColDef: ref(defaultColDef),
        domLayout: ref('autoHeight'),
        /** @type {V.Ref<AgGrid.ColumnDef[]>} */
        columnDefs: ref([]),
        rowSelection: ref('multiple'),
        rowData: computed(() =>
            actions.mapRecordsToRowData(selectedRecords.value)
        ),
        /** @type {V.Ref<GridApi>} */
        gridApi: ref(null),
        /** @type {V.Ref<ColumnApi>} */
        gridColumnApi: ref(null),
        rowHeight: ref(50),
    };

    // HOOKS
    const useDomLayout = (layout) => {
        state.domLayout.value = layout;
    };

    const useDefaultColDef = (defaultDefs) => {
        state.defaultColDef.value = defaultDefs;
    };

    const useColumnDefs = (colDefs) => {
        state.columnDefs.value = colDefs;
    };

    const useGridApi = (api) => {
        state.gridApi.value = api;
    };

    const useGridColumnApi = (api) => {
        state.gridColumnApi.value = api;
    };

    const useRowHeight = (height) => {
        state.rowHeight.value = height;
    };

    // GETTERS
    const getters = {
        // Allows us to reorganize easily.
        /** @returns {Record<String, AgGrid.ColumnDef>} */
        getAssignProfileColumnDefs: () => ({
            index: {
                headerName: '',
                field: 'index',
                width: 35,
                pinned: true,
                sortable: true,
                resizable: false,
            },
            location: {
                headerName: 'Location (Selected)',
                field: 'location',
                minWidth: 450,
                sortable: true,
                resizable: true,
                suppressMenu: true,
                suppressSizeToFit: false,
                cellClass: 'flex text-left items-center',
            },
            suggestion: {
                headerName: 'Suggested Mapping Profile',
                field: 'suggestion',
                width: 100,
                minWidth: 80,
                sortable: true,
                resizable: true,
                suppressMenu: true,
            },
            profile: {
                headerName: 'Mapping Profile',
                field: 'profile',
                width: 200,
                maxWidth: 200,
                sortable: true,
                resizable: false,
                suppressMenu: true,
                suppressSizeToFit: false,
                cellClass: 'h-full',
                cellRendererFramework: SelectProfileCellRenderer,
                cellRendererParams: {
                    label: 'Mapping Profile',
                    profileIndex,
                    /** @param {String} target */
                    show: (target) => {
                        onViewRecordMappingProfile({
                            filename: target,
                        });
                    },
                    /** @param {String} target */
                    edit: (target) => {
                        onEditRecordMappingProfile({
                            filename: target,
                        });
                    },
                },
            },
            name: {
                headerName: 'File Name',
                field: 'name',
                minWidth: 500,
                width: 500,
                cellClass: 'flex text-left items-center',
                pinned: true,
                resizable: true,
                sortable: true,
                suppressMenu: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
            },
            type: {
                headerName: 'File Type',
                field: 'type',
                valueGetter: getFileType,
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            size: {
                headerName: 'File Size',
                field: 'size',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            viewing: {
                headerName: '',
                field: 'viewing',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            editing: {
                headerName: '',
                field: 'editing',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            creating: {
                headerName: '',
                field: 'creating',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            created: {
                headerName: '',
                field: 'created',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            applying: {
                headerName: '',
                field: 'applying',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            applied: {
                headerName: '',
                field: 'applied',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            ingesting: {
                headerName: '',
                field: 'ingesting',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            ingested: {
                headerName: '',
                field: 'ingested',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
            isPEMFile: {
                headerName: '',
                field: 'isPEMFile',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
            },
        }),
        getRowIdFromFileName: (data) => data.name,
    };

    // ACTIONS
    const actions = {
        /**
         * @param {selectedRecords['value']} records
         */
        mapRecordsToRowData: (records) => {
            return records.map((record, index) => ({
                index,
                ...record,
                location: record?.location?.label,
            }));
        },
        updateColumnDefs: () => {
            const columnDefs = getters.getAssignProfileColumnDefs();
            useColumnDefs([
                columnDefs.name,
                columnDefs.location,
                columnDefs.profile,
            ]);
        },
        onGridReady: (event) => {
            state.gridApi.value = event.api;
            state.gridColumnApi.value = event.columnApi;
        },
        onColumnResized: (event) => {
            // See: grid.onColumnResized(event);
            state.gridApi.value?.refreshCells();
            emit('resize:grid', event); // Currently unused.
        },
        // ts-ignore
        onRowDataChanged: (event) => {
            state.gridApi.value?.sizeColumnsToFit();
            state.gridApi.value?.refreshCells();
            state.gridColumnApi.value?.autoSizeAllColumns(false);
        },
        // ts-ignore
        onChangeRowData: (event) => {
            state.gridApi.value?.refreshCells();
        },
        // /**
        //  * Explicit request to clear the record's transient mapping profile entry.
        //  * @param {Object} event
        //  * @param {String} event.filename Affected record filename.
        //  * @returns {Promise<Awaited<UploadRecord>>} When awaited, gives the affected record.
        //  */
        // onDropMappingProfile: async (event) => {
        //     const { filename } = event ?? {};
        //     if (isNil(event) || isNil(filename)) {
        //         throw new TypeError(`Missing required event parameters.`);
        //     }
        //     try {
        //         status.value.add('updating');
        //         status.value = new Set(...status.value);
        //         return await onProfileDeselected({ filename });
        //     } finally {
        //         status.value.delete('updating');
        //         status.value = new Set(...status.value);
        //     }
        // },
        // /**
        //  * Select an existing profile from the cached profile index as the record's transient mapping profile entry.
        //  * @param {Object} event
        //  * @param {String} event.filename Affected record filename.
        //  * @param {Pick<MappingProfileResource, 'id'>} [event.profile] Profile with selection id.
        //  * @returns {Promise<Awaited<UploadRecord>>} When awaited, gives the affected record.
        //  */
        // onSelectMappingProfile: async (event) => {
        //     const { filename, profile } = event ?? {};
        //     if (isNil(event) || isNil(filename) || isNil(profile)) {
        //         throw new TypeError(`Missing required event parameters.`);
        //     }
        //     const { id } = profile;
        //     if (isNil(id) || id === -1 || id < 0) {
        //         throw new Error(
        //             `No identifier provided. Cannot select existing profile.`
        //         );
        //     }
        //     try {
        //         status.value.add('updating');
        //         status.value = new Set(...status.value);
        //         return await onProfileSelected({
        //             filename,
        //             profile,
        //         });
        //     } finally {
        //         status.value.delete('updating');
        //         status.value = new Set(...status.value);
        //     }
        // },
        // /**
        //  * Provision a new placeholder profile as the record's transient mapping profile entry.
        //  * @param {Object} event
        //  * @param {String} event.filename Affected record filename.
        //  * @param {Omit<MappingProfileResource, 'id'|'accountId'|'dateCreated'|'createdBy'> & Partial<MappingProfileResource>} [event.definition] Profile definition without an identifier.
        //  * @returns {Promise<Awaited<UploadRecord>>} When awaited, gives the affected record.
        //  */
        // onProvisionMappingProfile: async (event) => {
        //     const { filename, definition } = event ?? {};
        //     if (isNil(event) || isNil(filename) || isNil(definition)) {
        //         throw new TypeError(`Missing required event parameters.`);
        //     }
        //     try {
        //         status.value.add('updating');
        //         status.value = new Set(...status.value);
        //         return await onProfileCreated({ filename, definition });
        //     } finally {
        //         status.value.delete('updating');
        //         status.value = new Set(...status.value);
        //     }
        // },
    };

    return {
        useGridApi,
        useGridColumnApi,
        useDefaultColDef,
        useColumnDefs,
        useDomLayout,
        useRowHeight,
        state,
        getters,
        actions,
        isUpdatingSelection,
    };
}

export default useProfileSelectGrid;
