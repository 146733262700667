<template>
    <div id="profile-assign-grid">
        <div
            v-if="isFileSelectionEmpty"
            class="flex flex-row text-gray-600"
        >
            <div class="text-sm text-center w-full">
                No files selected for upload.
            </div>
            <div class="text-sm text-center w-full">
                Please return to the previous step.
            </div>
        </div>
        <AgGridVue
            v-if="!isFileSelectionEmpty"
            class="ag-theme-alpine w-full block"
            style="min-height: 250px"
            :domLayout="grid.state.domLayout.value"
            :defaultColDef="grid.state.defaultColDef.value"
            :columnDefs="grid.state.columnDefs.value"
            :rowSelection="grid.state.rowSelection.value"
            :rowHeight="grid.state.rowHeight.value"
            :rowData="grid.state.rowData.value"
            @grid-ready="grid.actions.onGridReady"
            @column-resized="grid.actions.onColumnResized"
            @selection-changed="grid.actions.onSelectionChanged"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useProfileSelectGrid } from '~CSVUploader/hooks/grid/useProfileSelectGrid';

    // <!-- TYPES -->

    /** @typedef {import('@/models/v1/mappings/MappingProfile').MappingProfile} MappingProfile */
    /** @typedef {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} MappingProfileResource */
    /** @typedef {import('@/store/types/uploader/state/UploadRecord').UploadRecord} UploadRecord */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectProfileGrid',
        components: {
            AgGridVue,
        },
        props: {
            multiple: Boolean,
            prompt: {
                type: String,
                default: 'Select mapping profiles to assign.',
            },
            records: {
                /** @type {V.PropType<Map<String, UploadRecord>>} */
                type: Object,
                required: true,
            },
            profileIndex: {
                /** @type {V.PropType<Map<Number, MappingProfileResource>>} */
                type: Object,
                required: true,
            },
            mountKey: {
                type: Number,
                required: true,
            },
        },
        emits: [
            'resize:grid',
            'alert',
            'change:rowData',
            'view:profile',
            'edit:profile',
            'input:profile',
        ],
        setup(props, context) {
            // ==== PROPS ====
            const { records, profileIndex, mountKey } = toRefs(props);

            // ==== COMPUTED PROPERTIES ====
            /**
             * Is the file selection set empty?
             */
            const isFileSelectionEmpty = computed(() => {
                return records.value.size <= 0;
            });

            // ===== GRID =====
            /**
             * Grid props.
             */
            const selectGridProps = {
                records,
                profileIndex,
                mountKey,
            };

            /**
             * Grid API.
             */
            const selectGridActions = {
                /**
                 * Open the modal in view mode for the specified record.
                 * @param {{ filename: String }} event
                 */
                onViewRecordMappingProfile: (event) => {
                    const { filename } = event;
                    if (records.value.has(filename)) {
                        const record = records.value.get(filename);
                        console.dir(`[view::${filename}]`);
                        context.emit('view:profile', { record });
                    }
                },
                /**
                 * Open the modal in edit mode for the specified record.
                 * @param {{ filename: String }} event
                 */
                onEditRecordMappingProfile: (event) => {
                    const { filename } = event;
                    if (records.value.has(filename)) {
                        const record = records.value.get(filename);
                        console.dir(`[edit::${filename}]`);
                        context.emit('edit:profile', { record });
                    }
                },
            };

            // Get the file grid module.
            const grid = useProfileSelectGrid(
                context,
                useStore(),
                selectGridProps,
                selectGridActions
            );

            // TODO: Loading overlay could be shown: https://www.ag-grid.com/vue-data-grid/overlays/

            // Configure the grid module.
            grid.useDefaultColDef({
                flex: 1,
                minWidth: 50,
                width: 100,
                resizable: true,
                sortable: true,
                filter: false,
            });
            // Update the column definitions.
            grid.actions.updateColumnDefs();

            return {
                grid,
                isFileSelectionEmpty,
            };
        },
    });
</script>
