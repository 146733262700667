// <!-- API -->
import { ref } from 'vue';
import { useStore } from 'vuex';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
// ts-ignore

import { Store } from 'vuex';
import { ECNBState } from '@/store/types/ECNBStore';
import { UploadRecord } from '@/store/types/uploader/state';

/**
 * Using a store (or with a new instance), handle request operations.
 * @param {Store<ECNBState>} [vuexStore]
 */
export const useProfileSuggestor = (vuexStore) => {
    // STORE

    /** @type {Store<ECNBState>} */
    const store = vuexStore ?? useStore();

    // STATE

    /** @type {V.Ref<Boolean>} */
    const isFetchingSuggestions = ref(false);

    /** @type {V.Ref<Set<String>>} */
    const filenames = ref(new Set());

    /** @type {V.Ref<Map<Number, Map<String, import('@/models/v1/mappings/MappingProfile').MappingProfileResource>>>} */
    const suggestions = ref(new Map());

    // METHODS

    /**
     * Get the current cached suggestions.
     * @param {Map<String, UploadRecord>} records
     */
    const updateCachedSuggestions = (records) => {
        // Clear cached suggestions.
        filenames.value = new Set();
        suggestions.value = new Map();
        [...records.values()].forEach((record) => {
            const filename = record.file.value.name;
            const location = record.location.value.id;
            const suggestion = record.suggestedMappingProfile ?? null;
            filenames.value = filenames.value.add(filename);
            const outer = suggestions.value;
            const inner = suggestions.value.get(location) ?? new Map();
            if (!isNil(suggestion)) {
                suggestions.value = outer.set(
                    location,
                    inner.set(filename, suggestion)
                );
            } else {
                suggestions.value = outer.set(location, inner);
            }
        });
    };

    /**
     * @param {Number} id Location id.
     */
    const isLocationMissing = (id) => {
        const isLocationMapEmpty = suggestions.value.size === 0;
        const isMissingLocationKey =
            isNil(id) || id <= -1 || !suggestions.value.has(id);
        return isLocationMapEmpty || isMissingLocationKey;
    };

    /**
     * @param {Number} location Location id.
     * @param {String} filename Filename.
     */
    const isFilenameMissing = (location, filename) => {
        const isMissingFilename = isNil(filename) || filename.length === 0;
        return (
            isMissingFilename ||
            isLocationMissing(location) ||
            !suggestions.value.get(location).has(filename)
        );
    };

    /**
     * @param {Number} location Location id.
     * @param {String} filename Filename.
     */
    const isSuggestionMissing = (location, filename) => {
        return (
            isFilenameMissing(location, filename) ||
            isNil(suggestions.value.get(location).get(filename))
        );
    };

    /**
     * @param {Number[]} locations
     */
    const isAnyLocationMissing = (locations) => {
        return locations.some((location) => isLocationMissing(location));
    };

    /**
     * @param {[ location: Number, filenames: Set<String> ][]} entries
     */
    const isAnyFilenameMissing = (entries) => {
        return entries.some(([location, filenames]) => {
            return Array.from(filenames).some((name) =>
                isFilenameMissing(location, name)
            );
        });
    };

    /**
     * @param {[ location: Number, filenames: Set<String> ][]} entries
     */
    const isAnySuggestionMissing = (entries) => {
        return entries.some(([location, filenames]) => {
            return Array.from(filenames).some((name) =>
                isSuggestionMissing(location, name)
            );
        });
    };

    /**
     * Refetch the cached suggested mapping profiles for the page. Does not store the suggestions.
     * @param {Map<String, UploadRecord>} records Records we are suggesting mapping profiles for.
     * @param {Boolean} forceReload Clear cached suggestions, if true.
     * @returns {Promise<Map<Number, Map<String, import('@/models/v1/mappings/MappingProfile').MappingProfileResource>>>} Return suggestions.
     */
    const refreshMappingProfileSuggestions = async (
        records,
        forceReload = false
    ) => {
        try {
            isFetchingSuggestions.value = true;
            const ignoreCache = forceReload === true;
            const all = [...records.values()];
            const storedEntries = all.map(
                /** @returns {[ location: Number, filename: String ]} */
                (r) => [r.location.value.id, r.file.value.name]
            );
            /** @type {Map<Number, Set<String>>} */
            const reducedEntries = storedEntries.reduce((collection, next) => {
                const [location, filename] = next;
                /** @type {Map<Number, Set<String>>} */
                const outer = collection ?? new Map();
                const inner = collection.get(location) ?? new Set();
                return outer.set(location, inner.add(filename));
            }, new Map());
            const locations = Array.from(reducedEntries.keys());
            const pairs = Array.from(reducedEntries.entries());
            updateCachedSuggestions(records);
            if (
                ignoreCache ||
                isAnyLocationMissing(locations) ||
                isAnyFilenameMissing(pairs) ||
                isAnySuggestionMissing(pairs)
            ) {
                // Request new suggestions if:
                // - Cache is ignored,
                // - One or more locations is missing from cache.
                // - One or more filenames is missing from cache (under a given location).
                // - One or more filenames are missing a suggestion (under a given location).
                // Request the suggested locations.
                const { dispatch } = store;
                suggestions.value = await dispatch(
                    `uploader/data/suggestRecordMappingProfiles`
                );
                const isEmpty =
                    isNil(suggestions.value) ||
                    suggestions.value.size === 0 ||
                    Array.from(suggestions.value.values()).every(
                        (m) => isNil(m) || m.size === 0
                    );
                if (isEmpty) {
                    console.warn(`No suggestions found!`);
                }
            }
            // Return the cached (or just fetched) suggestions.
            return suggestions.value;
        } catch (err) {
            console.error(err);
        } finally {
            isFetchingSuggestions.value = false;
        }
    };

    return {
        refreshMappingProfileSuggestions,
        isFetchingSuggestions,
        store,
        suggestions,
    };
};
